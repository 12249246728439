import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { ResellerViewModel } from '../models/reseller.model';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { ModalService } from '@shared/services/modal.service';
import { MASTER_DOMAIN, RESELLER } from '@shared/utility/global.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    protected authService: AuthService,
    private localStorageService: LocalStorageService,
    private modalService: ModalService,
    private translate: TranslateService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Promise<boolean> {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);

    // Check access to reseller list
    if (!reseller.hostnames.includes(MASTER_DOMAIN) && state.url === `/${RESELLER.route}`) {
      return false;
    }

    // Check access to reseller management
    if (
      !reseller.hostnames.includes(MASTER_DOMAIN) &&
      route.routeConfig?.path === `${RESELLER.route}/:id` &&
      route.params['id'] !== reseller._id
    ) {
      return false;
    }

    return this.hasRequiredPermission(route.data['permission']);
  }

  private hasRequiredPermission(permission: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.authService.user$.subscribe(user => {
        if (user?.sa && this.authService.permissions.length < 1) {
          resolve(true);
        } else if (this.authService.permissions.length < 1) {
          this.authService.permissions$.subscribe(() => {
            resolve(this.isAllowed(permission));
          });
        } else {
          resolve(this.isAllowed(permission));
        }
      });
    });
  }

  private isAllowed(permission: string) {
    const allowed = this.authService.hasPermission(permission);

    if (!allowed) {
      const translation = this.translate.instant('common.permission-missing');
      this.modalService.onError(translation);
      console.log('permission', permission);
    }

    return allowed;
  }
}
