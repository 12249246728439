import { Injectable } from '@angular/core';

import { ThemeType } from '../enums/theme-type.enum';
import { LocalStorageService } from '@shared/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private currentTheme = ThemeType.default;

  constructor(private localStorageService: LocalStorageService) {}

  initTheme(theme: ThemeType) {
    this.currentTheme = theme;
    return this.loadTheme(true);
  }

  toggleTheme() {
    this.currentTheme = this.reverseTheme(this.currentTheme);
    this.localStorageService.setItem('theme', this.currentTheme);
    return this.loadTheme(false);
  }

  private loadCss(href: string, id: string) {
    return new Promise<Event>((resolve, reject) => {
      const style = document.createElement('link');
      style.rel = 'stylesheet';
      style.href = href;
      style.id = id;
      style.onload = resolve;
      style.onerror = reject;
      document.head.append(style);
    });
  }

  private loadTheme(firstLoad = true) {
    const theme = this.currentTheme;

    if (firstLoad) {
      document.documentElement.classList.add(theme);
    }

    return new Promise<Event>((resolve, reject) => {
      this.loadCss(`${theme}.css`, theme).then(
        event => {
          if (!firstLoad) {
            document.documentElement.classList.add(theme);
          }

          this.removeUnusedTheme(this.reverseTheme(theme));
          resolve(event);
        },
        error => reject(error)
      );
    });
  }

  private removeUnusedTheme(theme: ThemeType) {
    document.documentElement.classList.remove(theme);
    const removedThemeStyle = document.getElementById(theme);

    if (removedThemeStyle) {
      document.head.removeChild(removedThemeStyle);
    }
  }

  private reverseTheme(theme: ThemeType) {
    return theme === ThemeType.dark ? ThemeType.default : ThemeType.dark;
  }
}
