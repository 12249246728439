import { EventEmitter, Injectable, Type } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { NzModalService, OnClickCallback } from 'ng-zorro-antd/modal';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private modal: NzModalService, private translate: TranslateService) {}

  onConfirm(content: string, onOk: EventEmitter<any> | OnClickCallback<any>) {
    this.translate.get('common.alert').subscribe(translation => {
      this.modal.confirm({
        nzTitle: translation,
        nzContent: content,
        nzOnOk: onOk
      });
    });
  }

  onCreate(
    content: Type<any>,
    config?: { afterClose?: EventEmitter<any>; params?: Partial<any>; width?: string | number }
  ) {
    this.modal.create({
      nzContent: content,
      nzAfterClose: config?.afterClose,
      nzComponentParams: config?.params,
      nzFooter: null,
      nzWidth: config?.width || '520px'
    });
  }

  onError(content: string) {
    this.translate.get('common.alert').subscribe(translation => {
      this.modal.error({
        nzTitle: translation,
        nzContent: content
      });
    });
  }

  onInfo(content: string) {
    this.translate.get('common.alert').subscribe(translation => {
      this.modal.info({
        nzTitle: translation,
        nzContent: content
      });
    });
  }

  onSuccess(content: string, config?: { afterClose?: EventEmitter<any> }) {
    this.translate.get('common.alert').subscribe(translation => {
      this.modal.success({
        nzTitle: translation,
        nzContent: content,
        nzAfterClose: config?.afterClose
      });
    });
  }

  onWarning(content: string) {
    this.translate.get('common.alert').subscribe(translation => {
      this.modal.warning({
        nzTitle: translation,
        nzContent: content
      });
    });
  }
}
