import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AddResellerDto, ResellerViewModel, UpdateResellerDto } from '../models/reseller.model';
import { Result } from '@shared/models/result.model';
import { PUBLIC, RESELLER } from '@shared/utility/global.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResellerService {
  constructor(private http: HttpClient) {}

  add(data: AddResellerDto) {
    const form = new FormData();
    form.append('accentColor', data.accentColor);
    form.append('admin', data.admin);
    form.append('adminUrl', data.adminUrl);
    form.append('file', data.file);
    form.append('hostnames', data.hostnames.join(','));
    form.append('name', data.name);
    form.append('primaryColor', data.primaryColor);
    form.append('warnColor', data.warnColor);
    return this.http.post<Result<string>>(`${environment.api}${RESELLER.endpoint}`, form);
  }

  get(id: string) {
    return this.http.get<Result<ResellerViewModel | undefined>>(
      `${environment.api}${RESELLER.endpoint}/${id}`
    );
  }

  getByHostname() {
    return this.http.get<Result<ResellerViewModel | undefined>>(
      `${environment.api}${PUBLIC.endpoint}${RESELLER.endpoint}`
    );
  }

  list() {
    return this.http.get<Result<ResellerViewModel[]>>(`${environment.api}${RESELLER.endpoint}`);
  }

  update(id: string, data: UpdateResellerDto) {
    const form = new FormData();
    form.append('accentColor', data.accentColor);
    form.append('admin', data.admin);
    form.append('adminUrl', data.adminUrl);
    if (data.file) form.append('file', data.file);
    form.append('hostnames', data.hostnames.join(','));
    form.append('name', data.name);
    form.append('primaryColor', data.primaryColor);
    form.append('warnColor', data.warnColor);
    return this.http.put<Result<string>>(`${environment.api}${RESELLER.endpoint}/${id}`, form);
  }
}
