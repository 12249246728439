import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { ThemeType } from './core/enums/theme-type.enum';
import { ResellerService } from './core/services/reseller.service';
import { ThemeService } from './core/services/theme.service';
import { LocalStorageService } from './shared/services/local-storage.service';
import { RESELLER } from './shared/utility/global.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'angular-linkroomv2-admin';

  private dispose$ = new Subject<void>();

  constructor(
    private localStorageService: LocalStorageService,
    private resellerService: ResellerService,
    private themeService: ThemeService
  ) {}

  ngOnDestroy(): void {
    this.dispose$.next();
    this.dispose$.complete();
  }

  ngOnInit(): void {
    this.initReseller();
    this.initTheme();
  }

  private initReseller() {
    this.resellerService
      .getByHostname()
      .pipe(takeUntil(this.dispose$))
      .subscribe({
        next: response => {
          if (!response.data) return;

          const local = this.localStorageService.getItem(RESELLER.key);

          if (JSON.stringify(local) !== JSON.stringify(response.data)) {
            this.localStorageService.setItem(RESELLER.key, response.data);
          }
        },
        error: error => {
          console.log(error);
        }
      });
  }

  private initTheme() {
    const theme: ThemeType = this.localStorageService.getItem('theme');
    this.themeService.initTheme(theme || ThemeType.default);
  }
}
