import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { Role, RoleViewModel } from '../models/role.model';
import { ResellerViewModel } from '@core/models/reseller.model';
import { Result } from '@shared/models/result.model';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { RESELLER, ROLE } from '@shared/utility/global.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(
    private db: AngularFirestore,
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  add(data: Role) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ROLE.collection}`;
    return this.db.collection<Role>(path).add(data);
  }

  delete(id: string) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ROLE.collection}`;
    return this.db.collection<Role>(path).doc(id).delete();
  }

  get(id: string) {
    return this.http.get<Result<RoleViewModel | undefined>>(
      `${environment.api}${ROLE.endpoint}/${id}`
    );
  }

  list() {
    return this.http.get<Result<RoleViewModel[]>>(`${environment.api}${ROLE.endpoint}`);
  }

  update(id: string, data: Role) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ROLE.collection}`;
    return this.db.collection<Role>(path).doc(id).update(data);
  }
}
