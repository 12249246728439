import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AccessRequestDto } from '../models/access-request.model';
import { Result } from '@shared/models/result.model';
import { ACCESS_REQUEST } from '@shared/utility/global.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccessRequestService {
  constructor(private http: HttpClient) {}

  add(data: AccessRequestDto) {
    return this.http.post<Result<string>>(`${environment.api}${ACCESS_REQUEST.endpoint}`, data);
  }
}
