import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { InviteViewModel } from '../models/invite.model';
import { Result } from '@shared/models/result.model';
import { INVITE } from '@shared/utility/global.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InviteService {
  constructor(private http: HttpClient) {}

  get(id: string | null) {
    return this.http.get<Result<InviteViewModel | undefined>>(
      `${environment.api}${INVITE.endpoint}/${id}`
    );
  }
}
