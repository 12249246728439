import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';

import { lastValueFrom, map, Observable, startWith, Subject } from 'rxjs';

import { AddInviteDto, Invite, InviteViewModel, ResendInviteDto } from '../models/invite.model';
import { LicenseViewModel } from '../models/license.model';
import { _License, Organization, OrganizationViewModel } from '../models/organization.model';
import { _Organization, Profile, SetProfileDto, UpdateProfileDto } from '../models/profile.model';
import { Skybox } from '../models/skybox.model';
import { World } from '../models/world.model';
import { ResellerViewModel } from '@core/models/reseller.model';
import { FirestoreDocument } from '@shared/models/firestore-document';
import { Result } from '@shared/models/result.model';
import { LocalStorageService } from '@shared/services/local-storage.service';
import {
  INVITE,
  LICENSE,
  MISC_ROUTES,
  ORGANIZATION,
  PROFILE,
  RESELLER,
  SKYBOX,
  WORLD
} from '@shared/utility/global.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private _organization: (_Organization & FirestoreDocument) | null = null;
  private _organization$ = new Subject<(_Organization & FirestoreDocument) | null>();

  private _permissions: string[] = [];

  get organization() {
    return this._organization;
  }

  get organization$() {
    return this._organization$.pipe(startWith(this._organization));
  }

  constructor(
    private http: HttpClient,
    private db: AngularFirestore,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  hasPermission(permission: string, sa = false) {
    if (sa) return true;
    if (this._permissions.includes(permission)) return true;
    return false;
  }

  setPermissions(permissions: string[]) {
    this._permissions = permissions;
  }

  add(id: string, data: Organization) {
    return this.http.post(`${environment.api}${ORGANIZATION.endpoint}/${id}`, data);
  }

  addInvite(organization: string, data: AddInviteDto) {
    return this.http.post<Result<string>>(
      `${environment.api}${ORGANIZATION.endpoint}/${organization}${INVITE.endpoint}`,
      data
    );
  }

  addWorld(id: string, data: World & FirestoreDocument) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

    return this.db
      .collection<Organization>(path)
      .doc(id)
      .collection<World>(WORLD.collection)
      .doc(data._id)
      .set({
        description: data.description,
        name: data.name,
        v2: data.v2,
        active: data.active
      });
  }

  // addSkybox(id: string, data: SkyboxViewModel & FirestoreDocument) {
  //   const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
  //   const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

  //   return this.db
  //     .collection<Organization>(path)
  //     .doc(id)
  //     .collection<Skybox>(SKYBOX.collection)
  //     .doc(data._id)
  //     .set({
  //       name: data.name,
  //       image: data.image
  //     });
  // }

  addLicense(id: string, data: _License & FirestoreDocument) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

    return this.db
      .collection<Organization>(path)
      .doc(id)
      .collection<_License>(LICENSE.collection)
      .doc(data._id)
      .set({
        name: data.name,
        customerEmail: data.customerEmail,
        quantity: data.quantity,
        inUse: data.inUse,
        priceId: data.priceId,
        planId: data.planId,
        customerId: data.customerId,
        subscriptionId: data.subscriptionId
      });
  }

  addUser(id: string, idPay: string, data: Profile & FirestoreDocument) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

    return this.db
      .collection<Organization>(path)
      .doc(id)
      .collection<_License>(LICENSE.collection)
      .doc(idPay)
      .collection<Profile>('users')
      .doc(data._id)
      .set({
        active: data.active,
        email: data.email,
        expiration: data.expiration,
        name: data.name
      });
  }

  deactivateInvite(organization: string, invite: string) {
    return this.http.patch(
      `${environment.api}${ORGANIZATION.endpoint}/${organization}${INVITE.endpoint}/${invite}`,
      {}
    );
  }

  listLicenses(organization: string) {
    return this.http.get<Result<LicenseViewModel[]>>(
      `${environment.api}${ORGANIZATION.endpoint}/${organization}${LICENSE.endpoint}`
    );
  }

  getLicense(license: string, organization: string) {
    return this.http.get<Result<LicenseViewModel>>(
      `${environment.api}${ORGANIZATION.endpoint}/${organization}/${license}${LICENSE.endpoint}`
    );
  }

  updateInUseLicense(license: string, organization: string, inUse: number) {
    return this.http.patch<Result<void>>(
      `${environment.api}${ORGANIZATION.endpoint}/${inUse}/${organization}/${license}`,
      {}
    );
  }

  delete(id: string) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

    return this.db.collection<Organization>(path).doc(id).delete();
  }

  deleteInvite(organization: string, invite: string) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

    return this.db
      .collection<Organization>(path)
      .doc(organization)
      .collection<Invite>(INVITE.collection)
      .doc(invite)
      .delete();
  }

  deleteProfile(organization: string, profile: string) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

    return this.db
      .collection<Organization>(path)
      .doc(organization)
      .collection<Profile>(PROFILE.collection)
      .doc(profile)
      .delete();
  }

  deleteWorld(organization: string, world: string) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

    return this.db
      .collection<Organization>(path)
      .doc(organization)
      .collection<World>(WORLD.collection)
      .doc(world)
      .delete();
  }

  deleteSkybox(organization: string, skybox: string) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

    return this.db
      .collection<Organization>(path)
      .doc(organization)
      .collection<Skybox>(SKYBOX.collection)
      .doc(skybox)
      .delete();
  }

  deleteLicense(organization: string, license: string) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

    return this.db
      .collection<Organization>(path)
      .doc(organization)
      .collection<_License>(LICENSE.collection)
      .doc(license)
      .delete();
  }

  deleteUser(organization: string, license: string, user: string) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

    return this.db
      .collection<Organization>(path)
      .doc(organization)
      .collection<_License>(LICENSE.collection)
      .doc(license)
      .collection<Profile>('users')
      .doc(user)
      .delete();
  }

  get(id: string) {
    return this.http.get<Result<OrganizationViewModel | undefined>>(
      `${environment.api}${ORGANIZATION.endpoint}/${id}`
    );
  }

  // getLicense(
  //   id: string,
  //   idOrganization: string
  // ): Observable<(_License & FirestoreDocument) | undefined> {
  //   const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
  //   const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

  //   return this.db
  //     .collection<Organization>(path)
  //     .doc(idOrganization)
  //     .collection<_License>(LICENSE.collection)
  //     .doc(id)
  //     .get()
  //     .pipe(
  //       map(snapshot => {
  //         const data = snapshot.data();
  //         return data ? { _id: snapshot.id, ...data } : undefined;
  //       })
  //     );
  // }

  list(): Observable<(Organization & FirestoreDocument)[]> {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

    return this.db
      .collection<Organization>(path)
      .get()
      .pipe(map(snapshot => snapshot.docs.map(x => ({ _id: x.id, ...x.data() }))));
  }

  listInvites(organization: string) {
    return this.http.get<Result<InviteViewModel[]>>(
      `${environment.api}${ORGANIZATION.endpoint}/${organization}${INVITE.endpoint}`
    );
  }

  listProfiles(id: string): Observable<(Profile & FirestoreDocument)[]> {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

    return this.db
      .collection<Organization>(path)
      .doc(id)
      .collection<Profile>(PROFILE.collection)
      .get()
      .pipe(map(snapshot => snapshot.docs.map(x => ({ _id: x.id, ...x.data() }))));
  }

  listWorlds(id: string): Observable<(World & FirestoreDocument)[]> {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

    return this.db
      .collection<Organization>(path)
      .doc(id)
      .collection<World>(WORLD.collection)
      .get()
      .pipe(map(snapshot => snapshot.docs.map(x => ({ _id: x.id, ...x.data() }))));
  }

  listSkyboxes(id: string): Observable<(Skybox & FirestoreDocument)[]> {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

    return this.db
      .collection<Organization>(path)
      .doc(id)
      .collection<Skybox>(SKYBOX.collection)
      .get()
      .pipe(map(snapshot => snapshot.docs.map(x => ({ _id: x.id, ...x.data() }))));
  }

  // listLicenses(id: string): Observable<(_License & FirestoreDocument)[]> {
  //   const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
  //   const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

  //   return this.db
  //     .collection<Organization>(path)
  //     .doc(id)
  //     .collection<_License>(LICENSE.collection)
  //     .get()
  //     .pipe(map(snapshot => snapshot.docs.map(x => ({ _id: x.id, ...x.data() }))));
  // }

  listUsers(id: string, idLicense: string): Observable<(Profile & FirestoreDocument)[]> {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

    return this.db
      .collection<Organization>(path)
      .doc(id)
      .collection<_License>(LICENSE.collection)
      .doc(idLicense)
      .collection<Profile>('users')
      .get()
      .pipe(map(snapshot => snapshot.docs.map(x => ({ _id: x.id, ...x.data() }))));
  }

  async select(
    organization: (_Organization & FirestoreDocument) | null,
    options: { navigate: boolean; signOut: boolean }
  ) {
    if (options.navigate) await this.router.navigate(['/', MISC_ROUTES.home]);
    this._organization = organization;
    if (!options.signOut) this._organization$.next(organization);
  }

  setProfile(organization: string, dto: SetProfileDto) {
    return this.http.post(
      `${environment.api}${ORGANIZATION.endpoint}/${organization}${PROFILE.endpoint}`,
      dto
    );
  }

  profileHasLicense(organization: string, profile: string) {
    return this.http.get<Result<string>>(
      `${environment.api}${ORGANIZATION.endpoint}/has-license/${organization}/${profile}`
    );
  }

  updateLicense(organization: string, id: string, subscriptionId: string) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

    return this.db
      .collection<Organization>(path)
      .doc(organization)
      .collection<_License>(LICENSE.collection)
      .doc(id)
      .update({ subscriptionId: subscriptionId });
  }

  update(id: string, data: Organization) {
    return this.http.put(`${environment.api}${ORGANIZATION.endpoint}/${id}`, data);
  }

  updateProfile(email: string, data: UpdateProfileDto) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const paths$ = this.db
          .collectionGroup(PROFILE.collection, ref => ref.where('email', '==', email))
          .get()
          .pipe(map(snapshot => snapshot.docs.map(x => x.ref.path)));

        const paths = await lastValueFrom(paths$);

        const promises = paths.map(x =>
          this.db.doc(x).update({
            active: data.active,
            firstName: data.firstName,
            lastName: data.lastName,
            twitter: data.twitter ? data.twitter : '',
            facebook: data.facebook ? data.facebook : '',
            linkedIn: data.linkedIn ? data.linkedIn : '',
            instagram: data.instagram ? data.instagram : ''
          })
        );

        await Promise.all(promises);
        resolve();
      } catch (error) {
        console.log(error);
        reject();
      }
    });
  }

  listLicense(): Observable<(any & FirestoreDocument)[]> {
    return this.db
      .collection<any>('license')
      .get()
      .pipe(map(snapshot => snapshot.docs.map(x => ({ _id: x.id, ...x.data() }))));
  }

  getLicenseStripe(id: string) {
    return this.db
      .collection<any>('license')
      .doc(id)
      .get()
      .pipe(
        map(snapshot => {
          const data = snapshot.data().id;
          return data ? { _id: snapshot.id, data } : undefined;
        })
      );
  }

  updateIdLicense(organization: string, id: string, licenseId: string) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

    return this.db
      .collection<Organization>(path)
      .doc(organization)
      .collection<Profile>(PROFILE.collection)
      .doc(id)
      .update({ licenseId: licenseId });
  }

  deleteIdLicense(organization: string, id: string) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${ORGANIZATION.collection}`;

    return this.db
      .collection<Organization>(path)
      .doc(organization)
      .collection<Profile>(PROFILE.collection)
      .doc(id)
      .update({ licenseId: undefined });
  }

  resendInvite(organization: string, inviteCode: string, data: ResendInviteDto) {
    return this.http.post<Result<string>>(
      `${environment.api}${ORGANIZATION.endpoint}/${organization}${INVITE.endpoint}/${inviteCode}/resend`,
      data
    );
  }
}
