<div class="header">
  <h2 style="margin: 0">{{ 'invites.title' | translate }}</h2>
</div>

<form nz-form nzLayout="inline" [formGroup]="group" (ngSubmit)="onSubmit()">
  <nz-form-item>
    <nz-form-control [nzErrorTip]="emailError">
      <input
        nz-input
        formControlName="email"
        placeholder="{{ 'invites.placeholder' | translate }}"
        [style.width.px]="organizationService.organization ? 200 : 150"
      />

      <ng-template #emailError let-control>
        <ng-container *ngIf="control.hasError('email')">
          {{ 'common.error-tips.email-invalid' | translate }}
        </ng-container>
        <ng-container *ngIf="control.hasError('required')">
          {{ 'common.error-tips.email-required' | translate }}
        </ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control nzErrorTip="{{ 'assign-role.error-tips.role-required' | translate }}">
      <nz-select
        nzShowSearch
        formControlName="role"
        nzPlaceHolder="{{ 'common.role' | translate }}"
        [style.width.px]="organizationService.organization ? 200 : 150"
      >
        <nz-option
          *ngFor="let item of roles"
          [nzLabel]="item.name"
          [nzValue]="item._id"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <ng-container *ngIf="!organizationService.organization">
    <nz-form-item>
      <nz-form-control>
        <nz-select
          nzShowSearch
          formControlName="organization"
          nzPlaceHolder="{{ 'common.organization' | translate }}"
          [style.width.px]="150"
        >
          <nz-option
            *ngFor="let item of organizations"
            [nzLabel]="item.name"
            [nzValue]="item._id"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control>
        <nz-select
          nzAllowClear
          nzShowSearch
          formControlName="license"
          nzPlaceHolder="{{ 'invites.license' | translate }}"
          [style.width.px]="150"
        >
          <nz-option
            *ngFor="let item of licenses"
            [nzLabel]="item.planName || ''"
            [nzValue]="item._id"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </ng-container>

  <!-- <nz-form-item>
    <nz-form-control>
      <button nz-button nzType="primary" nzSearch (click)="onAdd($event)"> 
        {{ 'common.add' | translate }}
      </button>
    </nz-form-control>
  </nz-form-item> -->

  <nz-form-item>
    <nz-form-control>
      <button nz-button nzType="primary" [disabled]="!group.valid">
        {{ 'common.send' | translate }}
      </button>
    </nz-form-control>
  </nz-form-item>
</form>

<!-- <div style="margin: 0.5rem 0">
  <nz-tag *ngFor="let item of emails; let i = index" nzMode="closeable" (nzOnClose)="onClose(i)">
    {{ item.email }} ({{ onFormatRole(item.role) }})
  </nz-tag>
</div> -->

<nz-table
  #invitesTable
  [nzData]="invites"
  [nzLoading]="tableLoading"
  nzSize="middle"
  [nzPageSize]="20"
  style="margin: 1rem 0"
>
  <thead>
    <tr>
      <th>{{ 'common.pending' | translate }}</th>
      <th>{{ 'common.email' | translate }}</th>
      <th>{{ 'common.role' | translate }}</th>
      <th>{{ 'common.send-date' | translate }}</th>
      <th></th>
    </tr>
    <tr></tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of invitesTable.data">
      <td><label nz-checkbox nzDisabled [ngModel]="data.active"></label></td>
      <td>{{ data.email }}</td>
      <td>{{ onFormatRole(data.role) }}</td>
      <td>{{ data.createdOn | date }}</td>
      <td>
        <button
          nz-button
          nzType="primary"
          (click)="resendInvite(data)"
          [disabled]="!data.active || data.sent"
          style="margin-right: 1rem"
        >
          <i nz-icon [nzType]="data.sent ? 'check' : 'mail'"></i>
          {{ (data.sent ? 'common.sent' : 'common.resend') | translate }}
        </button>

        <button
          nz-button
          nzType="primary"
          nzDanger
          (click)="onDelete(data._id)"
          [disabled]="!data.active"
        >
          <i nz-icon nzType="delete"></i>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>

<app-spinner *ngIf="loading"></app-spinner>
