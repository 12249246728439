import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

import { Privacy } from '@features/models/privacy.model';
import { WorldSharedViewModel } from '@features/models/world.model';
import { OrganizationService } from '@features/services/organization.service';
import { ProfileService } from '@features/services/profile.service';
import { WorldService } from '@features/services/world.service';
import { ModalService } from '@shared/services/modal.service';
import { validateForm } from '@shared/utility/global.functions';

@Component({
  selector: 'app-share-world',
  templateUrl: './share-world.component.html',
  styleUrls: ['./share-world.component.scss']
})
export class ShareWorldComponent implements OnInit {
  loading = false;
  group!: FormGroup;
  privacyOptions: Privacy[] = [];
  selectPrivacy!: string;
  inputValue!: string;
  worlds: WorldSharedViewModel[] = [];
  tableLoading = false;
  placeholder = 'Profile email';

  @Input() worldId!: string;
  @Input() owner!: string;
  @Input() ownerId!: string;

  organizationId: string | undefined;
  userId: string | undefined;

  constructor(
    private builder: FormBuilder,
    private worldService: WorldService,
    private modalService: ModalService,
    private organizationService: OrganizationService,
    private profileService: ProfileService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.privacyOptions = [
      { name: 'To an organization', value: 'organizations' },
      { name: 'To a profile', value: 'profiles' }
    ];
    this.initData();
    this.initForm();
  }

  async submitForm() {
    try {
      if (this.group.valid) {
        this.loading = true;
        const privacy: 'profiles' | 'organizations' = this.group.value.selection;
        const { data } = await lastValueFrom(this.profileService.getId(this.group.value.input));

        if (!data) return this.showModal('error', 'world.errors.user-does-not-exist');

        if (privacy == 'profiles') {
          const value = await lastValueFrom(this.profileService.haveOrg(data, this.ownerId));
          if (this.ownerId === data || value.data === true)
            return this.showModal('error', 'world.errors.error-isalready-shared');
        }
        if (privacy == 'organizations') {
          if (this.ownerId === this.group.value.input)
            return this.showModal('error', 'world.errors.error-isalready-shared');
        }
        await lastValueFrom(
          this.worldService.shareWorld(
            this.worldId,
            {
              collection: privacy,
              collectionId: data || this.group.value.input
            },
            this.owner,
            this.ownerId
          )
        );

        this.initData();
        this.loading = false;
      } else {
        validateForm(this.group);
      }
    } catch (error) {
      this.showModal('error', 'world.errors.error-sharing-world');
      this.loading = false;
    }
  }

  private async initData() {
    this.tableLoading = true;
    try {
      const { data } = await lastValueFrom(
        this.worldService.listSharedUsers(this.worldId, this.owner, this.ownerId)
      );

      this.worlds = data;

      for (const world of this.worlds) {
        if (world.collection === 'organizations') {
          const organization = await lastValueFrom(
            this.organizationService.get(world.collectionId)
          );
          world.name = organization.data?.name;
        } else if (world.collection === 'profiles') {
          const profile = await lastValueFrom(this.profileService.get(world.collectionId));
          world.name = profile.data?.email;
        }
      }

      this.tableLoading = false;
      this.loading = false;
    } catch (error) {
      this.tableLoading = false;
      this.loading = false;
      this.showModal('error', 'world.errors.error-loading-data');
    }
  }

  private initForm() {
    this.group = this.builder.group({
      input: [null, [Validators.required, this.conditionalEmailValidator]],
      selection: ['profiles']
    });

    this.group.controls['selection'].valueChanges.subscribe(value => {
      this.placeholder =
        value === 'organizations' ? (this.placeholder = 'Organization ID') : 'Profile Email';

      // Reset input control when selection changes
      const inputControl = this.group.get('input');
      if (inputControl) {
        inputControl.reset();
        inputControl.setValidators([Validators.required, this.conditionalEmailValidator]);
        inputControl.updateValueAndValidity();
      }
    });
  }

  private conditionalEmailValidator(control: FormControl): { [key: string]: any } | null {
    const input = control.parent ? control.parent.get('input') : null;
    const selection = control.parent ? control.parent.get('selection') : null;

    if (input && selection && input.value && selection.value === 'profiles') {
      if (Validators.required(control) || Validators.email(control)) {
        return { email: true };
      }
    }

    return null;
  }

  async onDelete(id: string) {
    this.translate.get('world.list.modal.confirm-delete-share').subscribe((translation: string) => {
      this.modalService.onConfirm(translation, async () => {
        try {
          this.loading = true;
          await lastValueFrom(
            this.worldService.deleteSharedUsers(id, this.worldId, this.owner, this.ownerId)
          );
          this.initData();
        } catch (error) {
          this.translate
            .get('world.list.modal.error-delete-shared-user')
            .subscribe((translation: string) => {
              this.modalService.onError(translation);
              this.loading = false;
            });
        }
      });
    });
  }

  private showModal(action: 'error' | 'success', key: string) {
    this.translate.get(key).subscribe((translation: string) => {
      switch (action) {
        case 'error':
          this.modalService.onError(translation);
          break;
        case 'success':
          this.modalService.onSuccess(translation);
          break;
      }

      this.loading = false;
    });
  }
}
