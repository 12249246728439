import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

import { AuthService } from '@core/services/auth.service';
import { InviteViewModel } from '@features/models/invite.model';
import { _Organization } from '@features/models/profile.model';
import { InviteService } from '@features/services/invite.service';
import { OrganizationService } from '@features/services/organization.service';
import { ProfileService } from '@features/services/profile.service';
import { RoleService } from '@features/services/role.service';
import { ModalService } from '@shared/services/modal.service';

@Component({
  selector: 'app-view-invites',
  templateUrl: './view-invites.component.html',
  styleUrls: ['./view-invites.component.scss']
})
export class ViewInvitesComponent implements OnInit {
  invites: InviteViewModel[] = [];
  loading = false;
  tableLoading = false;

  constructor(
    private authService: AuthService,
    private inviteService: InviteService,
    private organizationService: OrganizationService,
    private profileService: ProfileService,
    private roleService: RoleService,
    private modalService: ModalService,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  async acceptInvite(inviteId: string) {
    this.loading = true;

    try {
      const invite = await lastValueFrom(this.inviteService.get(inviteId));

      if (!invite.data || !invite.data.active) {
        this.showModal('error', 'invites.errors.invite-error');
        return;
      }

      const [organization, role] = await Promise.all([
        lastValueFrom(this.organizationService.get(invite.data.organization)),
        lastValueFrom(this.roleService.get(invite.data.role))
      ]);

      if (!organization.data || !role.data || this.authService.user?.email !== invite.data.email) {
        this.showModal('error', 'invites.errors.invalid-invite');
        return;
      }

      await Promise.all([
        lastValueFrom(
          this.profileService.setOrganization(this.authService.user._id, {
            _id: organization.data._id,
            name: organization.data.name,
            roleId: role.data._id,
            roleName: role.data.name
          })
        ),
        lastValueFrom(
          this.organizationService.setProfile(organization.data._id, {
            _id: this.authService.user._id,
            active: this.authService.user.active,
            email: this.authService.user.email,
            firstName: this.authService.user.firstName,
            lastName: this.authService.user.lastName
          })
        ),
        lastValueFrom(
          this.organizationService.deactivateInvite(organization.data._id, invite.data._id)
        )
      ]);

      // Wait for previous operations to complete
      if (invite.data.license) {
        await lastValueFrom(
          this.profileService.addLicense({
            licenseId: invite.data.license,
            organizationId: organization.data._id,
            profileId: this.authService.user._id
          })
        );
      }

      this.initData();
      this.showModal('success', 'invites.modal.success');
      this.authService.refresh();
      this.router.navigate(['/home']);
    } catch (error) {
      this.showModal('error', 'invites.errors.invite-error');
    }
  }

  private async initData() {
    this.tableLoading = true;

    const { data } = await lastValueFrom(
      this.profileService.listInvites(this.authService.user?.email)
    );

    this.invites = data;
    this.tableLoading = false;
  }

  onDelete(id: string, organizationId: string) {
    this.translate.get('invites.modal.confirm-delete').subscribe((translation: string) => {
      this.modalService.onConfirm(translation, () => this.deactivateInvite(id, organizationId));
    });
  }

  private async deactivateInvite(id: string, organizationId: string) {
    try {
      this.tableLoading = true;
      await lastValueFrom(this.organizationService.deactivateInvite(organizationId, id));
      this.initData();
    } catch (error) {
      this.translate.get('invites.modal.delete-error').subscribe((translation: string) => {
        this.modalService.onError(translation);
        this.tableLoading = false;
      });
    }
  }

  private showModal(action: 'error' | 'success', key: string) {
    this.translate.get(key).subscribe((translation: string) => {
      switch (action) {
        case 'error':
          this.modalService.onError(translation);
          break;
        case 'success':
          this.modalService.onSuccess(translation);
          break;
      }

      this.loading = false;
    });
  }
}
