import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { en_US, es_ES, NzI18nService } from 'ng-zorro-antd/i18n';

import { LanguageItem } from '../models/language-item.model';
import { Language } from '../models/language.model';

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  languages: LanguageItem[] = [
    { label: 'English', value: { lang: 'en', locale: en_US } },
    { label: 'Español', value: { lang: 'es', locale: es_ES } }
  ];

  constructor(private i18n: NzI18nService, private translate: TranslateService) {
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('en');
    translate.use('en');
  }

  setLocale(value: Language) {
    this.translate.use(value.lang);
    this.i18n.setLocale(value.locale);
  }
}
