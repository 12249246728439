<nz-layout>
  <nz-header>
    <div class="container">
      <h1 style="color: white; margin: 0">
        {{
          organizationService.organization?.name || reseller?.name || ('common.admin' | translate)
        }}
      </h1>
      <div class="container">
        <button
          *ngIf="organizationService.organization && authService.user?.sa"
          nz-button
          nzType="primary"
          nzDanger
          (click)="onStopView()"
        >
          <i nz-icon nzType="eye-invisible"></i>
        </button>

        <button nz-button nzType="primary" (click)="onToggle()" class="element">
          <i nz-icon nzType="bulb"></i>
        </button>

        <button
          *ngIf="authService.user"
          nz-button
          nzType="primary"
          nz-dropdown
          [nzDropdownMenu]="userMenu"
          nzPlacement="bottomRight"
          nzTrigger="click"
          class="element"
        >
          <span *ngIf="organizationService.organization; else noOrganizationSelected">
            {{ organizationService.organization.name }} ({{
              organizationService.organization.roleName
            }})
          </span>
          <ng-template #noOrganizationSelected>
            {{ 'layout.none-selected' | translate }}
          </ng-template>
        </button>

        <nz-dropdown-menu #userMenu="nzDropdownMenu">
          <ul nz-menu>
            <li *ngIf="authService.user?.firstName" nz-menu-item>
              <i nz-icon nzType="user"></i> {{ authService.user?.firstName }}
            </li>

            <li nz-menu-item><i nz-icon nzType="mail"></i> {{ authService.user?.email }}</li>

            <li *ngIf="!authService.user?.sa" nz-menu-item (click)="onInvite()">
              <i nz-icon nzType="usergroup-add" nzTheme="outline"></i>
              {{ 'invites.my-invites' | translate }}
            </li>

            <li nz-menu-item [routerLink]="['/', userRoute, authService.user?._id]">
              <i nz-icon nzType="edit"></i> {{ 'common.edit-profile' | translate }}
            </li>

            <li
              *ngIf="authService.user?.sa || organizationService.organization"
              nz-menu-item
              (click)="onGalleryRedirect()"
            >
              <i nz-icon nzType="file-add"></i> {{ 'common.gallery-redirect' | translate }}
            </li>

            <li
              *ngIf="
                organizationService.hasPermission('can_use_fan_experience') || authService.user?.sa
              "
              nz-menu-item
              (click)="onVipAdminRedirect()"
            >
              <i nz-icon nzType="file-add"></i> {{ 'common.vip-redirect' | translate }}
            </li>

            <nz-divider
              *ngIf="organizationsList.length !== 0"
              style="margin: 0.5rem 0"
            ></nz-divider>

            <a
              nz-dropdown
              [nzDropdownMenu]="menu"
              *ngIf="organizationsList.length !== 0"
              style="margin-left: 0.7rem"
            >
              {{ 'layout.switch-organizations' | translate }}
              <i nz-icon nzType="down"></i>
            </a>

            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu nzSelectable>
                <li
                  *ngFor="let item of organizationsList"
                  nz-menu-item
                  (click)="onSelect(item, true)"
                  style="color: #1890ff"
                >
                  {{ item.name }} ({{ item.roleName }})
                </li>
              </ul>
            </nz-dropdown-menu>

            <nz-divider *ngIf="authService.user?.sa" style="margin: 0.5rem 0"></nz-divider>

            <li *ngIf="authService.user?.sa" nz-menu-item (click)="onView()">
              <i nz-icon nzType="eye"></i> {{ 'select-organization.title' | translate }}
            </li>

            <li
              *ngIf="reseller && authService.user?.email === reseller.admin"
              nz-menu-item
              [routerLink]="['/', resellerRoute, reseller._id]"
            >
              <i nz-icon nzType="edit"></i> {{ 'common.edit-reseller' | translate }}
            </li>

            <nz-divider style="margin: 0.5rem 0"></nz-divider>

            <li nz-menu-item (click)="onSignOut()" style="color: #ff4d4f">
              <i nz-icon nzType="logout"></i> {{ 'common.sign-out' | translate }}
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
  </nz-header>
  <nz-layout style="margin-top: 64px; min-height: calc(100vh - 64px)">
    <nz-sider *ngIf="authService.user" nzCollapsible nzBreakpoint="lg" [nzCollapsedWidth]="0">
      <ul nz-menu nzMode="inline" class="sider-menu">
        <!-- Home -->
        <li nz-menu-item nzMatchRouter>
          <a [routerLink]="['/', homeRoute]">
            <i nz-icon nzType="home"></i>
            {{ 'layout.home' | translate }}
          </a>
        </li>

        <!-- Menu Parents -->
        <ng-container *ngFor="let parent of menuParents">
          <!-- Submenu -->
          <ng-container *ngIf="parent.submenu; else menuItem">
            <li nz-submenu [nzIcon]="parent.icon" nzTitle="{{ parent.content | translate }}">
              <ul>
                <!-- Menu Children -->
                <ng-container *ngFor="let child of menuChildren">
                  <!-- Check if child belongs to parent -->
                  <li
                    *ngIf="child.parent === parent._id"
                    class="menu-item"
                    nz-menu-item
                    nzMatchRouter
                  >
                    <a class="menu-anchor" [routerLink]="['/', child.route]">
                      <!-- Change to your desired color -->
                      {{ child.content | translate }}
                    </a>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ng-container>
          <!-- Menu Item -->
          <ng-template #menuItem>
            <li nz-menu-item nzMatchRouter>
              <a [routerLink]="['/', parent.route]">
                <!-- Change to your desired color -->
                <i *ngIf="parent.icon" nz-icon [nzType]="parent.icon"></i>
                {{ parent.content | translate }}
              </a>
            </li>
          </ng-template>
        </ng-container>
      </ul>

      <div class="sider-footer">
        <a nz-dropdown [nzDropdownMenu]="languageMenu" nzPlacement="topCenter" nzTrigger="click">
          {{ languageSelected }}
        </a>

        <nz-dropdown-menu #languageMenu="nzDropdownMenu">
          <ul nz-menu>
            <li *ngFor="let item of languageList" nz-menu-item (click)="onChange(item)">
              {{ item.label }}
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </nz-sider>

    <nz-layout class="inner-layout">
      <nz-content>
        <router-outlet></router-outlet>
      </nz-content>
    </nz-layout>
  </nz-layout>
</nz-layout>
