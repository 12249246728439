import { NgModule } from '@angular/core';
import {
  AngularFireAuthGuard,
  canActivate,
  redirectLoggedInTo,
  redirectUnauthorizedTo
} from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import {
  ASSIGN_PEOPLE,
  AUTH,
  GALLERY,
  INVOICE,
  LICENSE,
  MENU,
  MISC_ROUTES,
  ORGANIZATION,
  PAY_MANAGEMENT,
  PERMISSION,
  PLAN,
  PLAN_UPGRADE,
  PROFILE,
  RECEIPT,
  RESELLER,
  ROLE,
  SCENE,
  SERVER_LIST,
  SKYBOX,
  STRIPE_CONNECT,
  SUPPORT_ACCESS,
  WORLD,
  WORLD_PIXEL
} from './shared/utility/global.constants';

const redirectLoggedInToHome = () => redirectLoggedInTo([MISC_ROUTES.home]);
const redirectUnauthorizedToSignIn = () => redirectUnauthorizedTo([AUTH.routes.signIn]);

const routes: Routes = [
  { path: '', redirectTo: AUTH.routes.signIn, pathMatch: 'full' },
  {
    path: 'accept-invite',
    loadChildren: () =>
      import('./features/components/accept-invite/accept-invite.module').then(
        m => m.AcceptInviteModule
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn }
  },
  {
    path: AUTH.routes.signIn,
    loadChildren: () =>
      import('./core/components/sign-in/sign-in.module').then(m => m.SignInModule),
    ...canActivate(redirectLoggedInToHome)
  },
  {
    path: AUTH.routes.signUp,
    loadChildren: () =>
      import('./core/components/sign-up/sign-up.module').then(m => m.SignUpModule),
    ...canActivate(redirectLoggedInToHome)
  },
  {
    path: AUTH.routes.forgotPassword,
    loadChildren: () =>
      import('./core/components/forgot-password/forgot-password.module').then(
        m => m.ForgotPasswordModule
      ),
    ...canActivate(redirectLoggedInToHome)
  },
  {
    path: MISC_ROUTES.home,
    loadChildren: () => import('./features/components/home/home.module').then(m => m.HomeModule),
    ...canActivate(redirectUnauthorizedToSignIn)
  },
  {
    path: ORGANIZATION.route,
    loadChildren: () =>
      import('./features/components/organization-list/organization-list.module').then(
        m => m.OrganizationListModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: ORGANIZATION.permissions.list }
  },
  {
    path: `${ORGANIZATION.route}/:id`,
    loadChildren: () =>
      import('./features/components/organization-management/organization-management.module').then(
        m => m.OrganizationManagementModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToSignIn,
      permission: ORGANIZATION.permissions.update
    }
  },
  {
    path: PROFILE.route,
    loadChildren: () =>
      import('./features/components/user-list/user-list.module').then(m => m.UserListModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: PROFILE.permissions.list }
  },
  {
    path: `${PROFILE.route}/:id`,
    loadChildren: () =>
      import('./features/components/user-management/user-management.module').then(
        m => m.UserManagementModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: PROFILE.permissions.update }
  },
  {
    path: PERMISSION.route,
    loadChildren: () =>
      import('./features/components/permission-list/permission-list.module').then(
        m => m.PermissionListModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: PERMISSION.permissions.list }
  },
  {
    path: `${PERMISSION.route}/:id`,
    loadChildren: () =>
      import('./features/components/permission-management/permission-management.module').then(
        m => m.PermissionManagementModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: PERMISSION.permissions.update }
  },
  {
    path: ROLE.route,
    loadChildren: () =>
      import('./features/components/role-list/role-list.module').then(m => m.RoleListModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: ROLE.permissions.list }
  },
  {
    path: `${ROLE.route}/:id`,
    loadChildren: () =>
      import('./features/components/role-management/role-management.module').then(
        m => m.RoleManagementModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: ROLE.permissions.update }
  },
  {
    path: SKYBOX.route,
    loadChildren: () =>
      import('./features/components/skybox-list/skybox-list.module').then(m => m.SkyboxListModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: SKYBOX.permissions.list }
  },
  {
    path: `${SKYBOX.route}/:id`,
    loadChildren: () =>
      import('./features/components/skybox-management/skybox-management.module').then(
        m => m.SkyboxManagementModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: SKYBOX.permissions.update }
  },
  {
    path: WORLD.route,
    loadChildren: () =>
      import('./features/components/world-list/world-list.module').then(m => m.WorldListModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: WORLD.permissions.list }
  },
  {
    path: `${WORLD.route}/:idWorld`,
    loadChildren: () =>
      import('./features/components/world-management/world-management.module').then(
        m => m.WorldManagementModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: WORLD.permissions.update }
  },
  {
    path: `${WORLD_PIXEL.route}/:idWorld`,
    loadChildren: () =>
      import('./features/components/world-pixel-management/world-pixel-management.module').then(
        m => m.WorldPixelManagementModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToSignIn,
      permission: WORLD_PIXEL.permissions.update
    }
  },
  {
    path: `${SCENE.route}/:idWorld/:idScene/:est`,
    loadChildren: () =>
      import('./features/components/scene-management/scene-management.module').then(
        m => m.SceneManagementModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: SCENE.permissions.update }
  },
  {
    path: `${MISC_ROUTES.assignSkybox}/:id`,
    loadChildren: () =>
      import('./features/components/assign-skybox/assign-skybox.module').then(
        m => m.AssignSkyboxModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: SKYBOX.permissions.update }
  },
  {
    path: `${MISC_ROUTES.assignWorld}/:id`,
    loadChildren: () =>
      import('./features/components/assign-world/assign-world.module').then(
        m => m.AssignWorldModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: WORLD.permissions.update }
  },
  {
    path: MENU.route,
    loadChildren: () =>
      import('./features/components/menu-list/menu-list.module').then(m => m.MenuListModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: MENU.permissions.list }
  },
  {
    path: `${MENU.route}/:id`,
    loadChildren: () =>
      import('./features/components/menu-management/menu-management.module').then(
        m => m.MenuManagementModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: MENU.permissions.update }
  },
  {
    path: RESELLER.route,
    loadChildren: () =>
      import('./features/components/reseller-list/reseller-list.module').then(
        m => m.ResellerListModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: RESELLER.permissions.list }
  },
  {
    path: `${RESELLER.route}/:id`,
    loadChildren: () =>
      import('./features/components/reseller-management/reseller-management.module').then(
        m => m.ResellerManagementModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: RESELLER.permissions.update }
  },
  {
    path: PLAN.route,
    loadChildren: () =>
      import('./features/components/plan-list/plan-list.module').then(m => m.PlanListModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: PLAN.permissions.list }
  },
  {
    path: `${PLAN.route}/:id`,
    loadChildren: () =>
      import('./features/components/plan-management/plan-management.module').then(
        m => m.PlanManagementModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: PLAN.permissions.update }
  },
  {
    path: MISC_ROUTES.notFound,
    loadChildren: () =>
      import('./core/components/not-found/not-found.module').then(m => m.NotFoundModule)
  },
  {
    path: 'stripe',
    loadChildren: () =>
      import('./features/components/stripe/stripe.module').then(m => m.StripeModule)
  },
  {
    path: 'success',
    loadChildren: () =>
      import('./features/components/success/success.module').then(m => m.SuccessModule),
    canActivate: [AngularFireAuthGuard],
    // This route does not need permission validation (xd :v)
    data: { authGuardPipe: redirectUnauthorizedToSignIn }
  },
  {
    path: LICENSE.route,
    loadChildren: () =>
      import('./features/components/license-list/license-list.module').then(
        m => m.LicenseListModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: LICENSE.permissions.list }
  },
  {
    path: PLAN_UPGRADE.route,
    loadChildren: () =>
      import('./features/components/plan-charge/plan-charge.module').then(m => m.PlanChargeModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: PLAN_UPGRADE.permissions.list }
  },
  {
    path: `${ASSIGN_PEOPLE.route}/:id`,
    loadChildren: () =>
      import('./features/components/assign-people/assign-people.module').then(
        m => m.AssignPeopleModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToSignIn,
      permission: ASSIGN_PEOPLE.permissions.create
    }
  },
  {
    path: `${PAY_MANAGEMENT.route}/:idPlan`,
    loadChildren: () =>
      import('./features/components/pay-management/pay-management.module').then(
        m => m.PayManagementModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToSignIn,
      permission: PAY_MANAGEMENT.permissions.buy
    }
  },
  {
    path: INVOICE.route,
    loadChildren: () =>
      import('./features/components/list-invoices/list-invoices.module').then(
        m => m.ListInvoicesModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: INVOICE.permissions.list }
  },
  {
    path: RECEIPT.route,
    loadChildren: () =>
      import('./features/components/receipt-list/receipt-list.module').then(
        m => m.ReceiptListModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: RECEIPT.permissions.list }
  },
  {
    path: GALLERY.route,
    loadChildren: () =>
      import('./features/components/gallery-list/gallery-list.module').then(
        m => m.GalleryListModule
      )
    //canActivate: [AngularFireAuthGuard, AuthGuard],
    //data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: GALLERY.permissions.list }
  },
  {
    path: SERVER_LIST.route,
    loadChildren: () =>
      import('./features/components/pixel-streaming/pixel-streaming.module').then(
        m => m.PixelStreamingModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: SERVER_LIST.permissions.list }
  },
  {
    path: AUTH.routes.resetPassword,
    loadChildren: () =>
      import('./core/components/reset-password/reset-password.module').then(
        m => m.ResetPasswordModule
      )
  },
  {
    path: 'support-access',
    loadChildren: () =>
      import('./features/components/support-access/support-access.module').then(
        m => m.SupportAccessModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: SUPPORT_ACCESS.permission }
  },
  {
    path: 'stripe-connect',
    loadChildren: () =>
      import('./features/components/stripe-connect/stripe-connect.module').then(
        m => m.StripeConnectModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignIn, permission: STRIPE_CONNECT.permission }
  },
  { path: '**', redirectTo: MISC_ROUTES.notFound }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
