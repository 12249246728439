<div class="header" style="margin-bottom: 1rem">
  <h2 style="margin: 0">{{ 'world.share-world' | translate }}</h2>
</div>

<form nz-form nzLayout="inline" [formGroup]="group" (ngSubmit)="submitForm()">
  <nz-form-item style="width: 35%">
    <nz-form-control [nzErrorTip]="inputError">
      <nz-select formControlName="selection" nzShowSearch>
        <nz-option
          *ngFor="let item of privacyOptions"
          [nzValue]="item.value"
          [nzLabel]="item.name"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control [nzErrorTip]="inputError">
      <input nz-input formControlName="input" [placeholder]="placeholder" />
    </nz-form-control>
    <ng-template #inputError let-control>
      <ng-container *ngIf="control.hasError('email')">
        {{ 'common.error-tips.email-invalid' | translate }}
      </ng-container>
      <ng-container *ngIf="control.hasError('required')">
        {{ 'common.error-tips.required' | translate }}
      </ng-container>
    </ng-template>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control>
      <button nz-button nzType="primary">Share</button>
    </nz-form-control>
  </nz-form-item>
</form>
<div class="header" style="margin-top: 1rem">
  <h3 style="margin: 0">{{ 'world.my-shares' | translate }}</h3>
</div>
<nz-table
  #worldsTable
  [nzData]="worlds"
  [nzLoading]="tableLoading"
  nzSize="middle"
  [nzPageSize]="20"
  style="margin: 1rem 0"
>
  <thead>
    <tr>
      <th>{{ 'world.identifier' | translate }}</th>
      <th>{{ 'world.type' | translate }}</th>
      <th></th>
    </tr>
    <tr></tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of worldsTable.data">
      <td>{{ data.name }}</td>
      <td>{{ data.collection }}</td>
      <td>
        <button nz-button nzType="primary" nzDanger (click)="onDelete(data._id)">
          <i nz-icon nzType="delete"></i>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>

<app-spinner *ngIf="loading"></app-spinner>
