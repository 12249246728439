import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { forkJoin, lastValueFrom } from 'rxjs';

import { Organization } from '@features/models/organization.model';
import { ProfileViewModel } from '@features/models/profile.model';
import { Role } from '@features/models/role.model';
import { OrganizationService } from '@features/services/organization.service';
import { ProfileService } from '@features/services/profile.service';
import { RoleService } from '@features/services/role.service';
import { FirestoreDocument } from '@shared/models/firestore-document';
import { ModalService } from '@shared/services/modal.service';
import { validateForm } from '@shared/utility/global.functions';

@Component({
  selector: 'app-assign-role',
  templateUrl: './assign-role.component.html',
  styleUrls: ['./assign-role.component.scss']
})
export class AssignRoleComponent implements OnInit {
  group!: FormGroup;
  loading = true;
  organizations: (Organization & FirestoreDocument)[] = [];
  roles: (Role & FirestoreDocument)[] = [];

  @Input() private id!: string;
  private profile!: ProfileViewModel;

  constructor(
    private builder: FormBuilder,
    private modalRef: NzModalRef,
    private modalService: ModalService,
    private organizationService: OrganizationService,
    private profileService: ProfileService,
    private roleService: RoleService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initData();
    this.initForm();
    this.initProfile();
  }

  onSubmit() {
    if (this.group.valid) {
      if (this.group.value.role) {
        this.makeOrganization();
      } else {
        this.deleteOrganization();
      }
    } else {
      validateForm(this.group);
    }
  }

  private initData() {
    forkJoin([this.organizationService.list(), this.roleService.list()]).subscribe({
      next: ([organizations, roles]) => {
        this.organizations = organizations;
        this.roles = roles.data;
      },
      error: error => {
        this.translate.get('assign-role.modal.init-error').subscribe((translation: string) => {
          this.modalService.onError(translation);
        });
      }
    });
  }

  private initForm() {
    this.group = this.builder.group({
      email: [{ value: null, disabled: true }],
      name: [{ value: null, disabled: true }],
      organization: [null, [Validators.required]],
      role: [null]
    });

    this.group.controls['organization'].valueChanges.subscribe(
      (value: Organization & FirestoreDocument) => {
        if (value) {
          this.getOrganization(value._id);
        }
      }
    );
  }

  private initProfile() {
    this.profileService.get(this.id).subscribe({
      next: response => {
        if (response.data) {
          this.group.patchValue({
            email: response.data.email,
            name: `${response.data.firstName || ''} ${response.data.lastName || ''}`
          });
          console.log(response.data);
          this.profile = response.data;
        } else {
          this.translate
            .get('users.management.modal.profile-invalid', { id: this.id })
            .subscribe((translation: string) => {
              this.modalService.onError(translation);
            });
        }

        this.loading = false;
      },
      error: error => {
        this.loading = false;

        this.translate
          .get('users.management.modal.profile-error')
          .subscribe((translation: string) => {
            this.modalService.onError(translation);
          });
      }
    });
  }

  private async makeOrganization() {
    try {
      this.loading = true;
      const organization: Organization & FirestoreDocument = this.group.value.organization;

      await lastValueFrom(
        this.profileService.setOrganization(this.id, {
          _id: organization._id,
          name: organization.name,
          roleId: this.group.value.role,
          roleName: this.roles.find(x => x._id === this.group.value.role)?.name || ''
        })
      );
      const { data } = await lastValueFrom(
        this.organizationService.profileHasLicense(organization._id, this.profile._id)
      );
      const licenseId = data;

      await lastValueFrom(
        this.organizationService.setProfile(organization._id, {
          _id: this.profile._id,
          active: this.profile.active,
          email: this.profile.email,
          firstName: this.profile.firstName,
          lastName: this.profile.lastName,
          licenseId: licenseId
        })
      );

      this.loading = false;
      this.modalRef.close('ok');
    } catch (error) {
      this.translate.get('assign-role.modal.make-error').subscribe((translation: string) => {
        this.loading = false;
        this.modalService.onError(translation);
      });
    }
  }

  private async deleteOrganization() {
    try {
      this.loading = true;
      const organization: Organization & FirestoreDocument = this.group.value.organization;
      await this.profileService.deleteOrganization(this.id, organization._id);
      await this.organizationService.deleteProfile(organization._id, this.id);
      this.loading = false;
      this.modalRef.close('ok');
    } catch (error) {
      this.translate.get('assign-role.modal.remove-error').subscribe((translation: string) => {
        this.modalService.onError(translation);
      });
    }
  }

  private getOrganization(id: string) {
    this.loading = true;

    this.profileService.getOrganization(this.id, id).subscribe({
      next: organization => {
        this.loading = false;
        this.group.controls['role'].patchValue(
          organization && organization.roleId ? organization.roleId : null
        );
      },
      error: error => {
        this.loading = false;

        this.translate
          .get('organizations.management.modal.organization-error')
          .subscribe((translation: string) => {
            this.modalService.onError(translation);
          });
      }
    });
  }
}
