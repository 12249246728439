<h2>{{ 'invites.my-invites' | translate }}</h2>
<nz-table
  #invitesTable
  [nzData]="invites"
  [nzLoading]="tableLoading"
  nzSize="middle"
  [nzPageSize]="20"
  style="margin: 1rem 0"
>
  <thead>
    <tr>
      <th>{{ 'common.organization' | translate }}</th>
      <th>{{ 'common.role' | translate }}</th>
      <th></th>
    </tr>
    <tr></tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of invitesTable.data">
      <td>{{ data.organizationName }}</td>
      <td>{{ data.roleName }}</td>
      <td>
        <button
          nz-button
          nzType="primary"
          style="margin-right: 1rem"
          (click)="acceptInvite(data._id)"
        >
          {{ 'accept-invite.confirm' | translate }}
        </button>

        <button
          nz-button
          nzType="primary"
          nzDanger
          (click)="onDelete(data._id, data.organization)"
          [disabled]="!data.active"
        >
          <i nz-icon nzType="delete"></i>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>

<app-spinner *ngIf="loading"></app-spinner>
