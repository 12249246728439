import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import es from '@angular/common/locales/es';
import { NgModule } from '@angular/core';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './core/components/layout/layout.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { ResellerInterceptor } from './core/interceptors/reseller.interceptor';
import { AccessRequestComponent } from './features/components/access-request/access-request.component';
import { AddCodeComponent } from './features/components/add-code/add-code.component';
import { AssignRoleComponent } from './features/components/assign-role/assign-role.component';
import { AssignUserComponent } from './features/components/assign-user/assign-user.component';
import { InvitesComponent } from './features/components/invites/invites.component';
import { RoomsComponent } from './features/components/rooms/rooms.component';
import { SelectOrganizationComponent } from './features/components/select-organization/select-organization.component';
import { ShareWorldComponent } from './features/components/share-world/share-world.component';
import { SkyboxDeleteComponent } from './features/components/skybox-delete/skybox-delete.component';
import { ViewInvitesComponent } from './features/components/view-invites/view-invites.component';
import { SharedModule } from './shared/shared.module';

registerLocaleData(en);
registerLocaleData(es);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    AccessRequestComponent,
    AddCodeComponent,
    AssignRoleComponent,
    AssignUserComponent,
    InvitesComponent,
    RoomsComponent,
    SelectOrganizationComponent,
    ShareWorldComponent,
    SkyboxDeleteComponent,
    ViewInvitesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResellerInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
