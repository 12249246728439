export const ACCESS_REQUEST = {
  endpoint: '/access-requests'
};

export const ASSIGN_PEOPLE = {
  permissions: {
    create: 'create_assign_people',
    get: 'get_assign_people'
  },
  route: 'assign-people'
};

export const AUTH = {
  endpoint: '/auth',
  routes: {
    forgotPassword: 'forgot-password',
    resetPassword: 'reset-password',
    signIn: 'sign-in',
    signUp: 'sign-up'
  }
};

export const COLORS = {
  primary: '#001529',
  accent: '#1890ff',
  warn: '#ff4d4f'
};

export const GRADIENT = {
  first: '#ff8b10',
  second: '#1890ff',
  thid: '#001529'
};

export const GALLERY = {
  collection: 'assets',
  endpoint: '/assets',
  permissions: {
    list: 'list_assets'
  },
  route: 'assets'
};

export const INVITE = {
  collection: 'invites',
  endpoint: '/invites'
};

export const INVOICE = {
  permissions: {
    list: 'list_invoices'
  },
  route: 'invoices'
};

export const LICENSE = {
  collection: 'licenses',
  endpoint: '/licenses',
  permissions: {
    create: 'create_license',
    delete: 'delete_license',
    deleteBulk: 'delete_bulk_license',
    get: 'get_license',
    list: 'list_license',
    update: 'update_license'
  },
  route: 'licenses'
};

export const MASTER_DOMAIN = 'linkroomv2-admin-dev.web.app';

export const MISC_ROUTES = {
  assignWorld: 'assign-world',
  assignSkybox: 'assign-skybox',
  assignUser: 'assign-user',
  home: 'home',
  notFound: 'not-found'
};

export const MENU = {
  collection: 'menus',
  permissions: {
    create: 'create_menu',
    delete: 'delete_menu',
    deleteBulk: 'delete_bulk_menu',
    get: 'get_menu',
    list: 'list_menus',
    update: 'update_menus'
  },
  route: 'menu-items'
};

export const NEW_ID = 'new';

export const ORGANIZATION = {
  collection: 'organizations',
  endpoint: '/organizations',
  header: 'Linkroom-Organization',
  isoPath: '{0}/iso.png',
  logoPath: '{0}/logo.{1}',
  logoPathNoExt: '{0}/logo',
  permissions: {
    create: 'create_organization',
    delete: 'delete_organization',
    deleteBulk: 'delete_bulk_organization',
    get: 'get_organization',
    list: 'list_organizations',
    update: 'update_organizations'
  },
  route: 'organizations'
};

export const PATTERN = {
  hostname: /^(?!www\.|[a-zA-Z]:\\|\/\/)[^\s\.]+\.[^\s]{2,}$/,
  permission: /^((?:[a-z0-9]+_)+[a-z0-9]+)$/
};

export const PAY_MANAGEMENT = {
  permissions: {
    create: 'create_pay_management',
    buy: 'can_buy_plan'
  },
  route: 'pay-management'
};

export const PERMISSION = {
  collection: 'permissions',
  permissions: {
    create: 'create_permission',
    delete: 'delete_permission',
    deleteBulk: 'delete_bulk_permission',
    get: 'get_permission',
    list: 'list_permissions',
    update: 'update_permissions'
  },
  route: 'permissions'
};

export const PLAN = {
  collection: 'plans',
  permissions: {
    create: 'create_plan',
    delete: 'delete_plan',
    deleteBulk: 'delete_bulk_plan',
    get: 'get_plan',
    list: 'list_plans',
    update: 'update_plans'
  },
  route: 'plans'
};

export const PLAN_UPGRADE = {
  permissions: {
    create: 'create_plan_upgrade',
    list: 'list_plan_upgrade'
  },
  route: 'plan-upgrade'
};

export const PROFILE = {
  collection: 'profiles',
  endpoint: '/profiles',
  permissions: {
    create: 'create_user',
    delete: 'delete_user',
    deleteBulk: 'delete_bulk_user',
    get: 'get_user',
    list: 'list_users',
    update: 'update_user'
  },
  route: 'people'
};

export const PUBLIC = {
  endpoint: '/public'
};

export const RECEIPT = {
  permissions: {
    list: 'list_receipts'
  },
  route: 'receipts'
};

export const RESELLER = {
  collection: 'resellers',
  current: 'linkroomv2-admin-dev.web.app',
  endpoint: '/resellers',
  header: 'Linkroom-Reseller',
  key: 'reseller',
  permissions: {
    create: 'create_reseller',
    delete: 'delete_reseller',
    deleteBulk: 'delete_bulk_reseller',
    get: 'get_reseller',
    list: 'list_resellers',
    update: 'update_resellers'
  },
  route: 'resellers'
};

export const ROOM = {
  collection: 'rooms'
};

export const ROLE = {
  collection: 'roles',
  endpoint: '/roles',
  permissions: {
    create: 'create_role',
    delete: 'delete_role',
    deleteBulk: 'delete_bulk_role',
    get: 'get_role',
    list: 'list_roles',
    update: 'update_roles'
  },
  route: 'roles'
};

export const SCENE = {
  collection: 'scenes',
  permissions: {
    create: 'create_scene',
    delete: 'delete_scene',
    deleteBulk: 'delete_bulk_scene',
    get: 'get_scene',
    list: 'list_scenes',
    update: 'update_scene'
  },
  route: 'scenes'
};

export const SERVER_LIST = {
  permissions: {
    list: 'list_server'
  },
  route: 'server-list'
};

export const STRIPE_CONNECT = {
  permission: 'can_stripe_connect',
  route: 'stripe-connect'
};

export const SKYBOX = {
  collection: 'skyboxes',
  endpoint: '/skyboxes',
  permissions: {
    create: 'create_skybox',
    delete: 'delete_skybox',
    deleteBulk: 'delete_bulk_skybox',
    get: 'get_skybox',
    list: 'list_skyboxes',
    update: 'update_skybox'
  },
  route: 'skyboxes'
};

export const SUPPORT_ACCESS = {
  endpoint: '/support-access',
  permission: 'can_support',
  route: 'support-access'
};

export const WORLD = {
  collection: 'worlds',
  endpoint: '/worlds',
  permissions: {
    create: 'create_world',
    delete: 'delete_world',
    deleteBulk: 'delete_bulk_world',
    get: 'get_world',
    list: 'list_worlds',
    update: 'update_world'
  },
  route: 'worlds'
};

export const WORLD_PIXEL = {
  collection: 'pixelWorlds',
  endpoint: '/pixel-worlds',
  permissions: {
    create: 'create_world',
    delete: 'delete_world',
    deleteBulk: 'delete_bulk_world',
    get: 'get_world',
    list: 'list_worlds',
    update: 'update_world'
  },
  route: 'pixel-worlds'
};
