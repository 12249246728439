<ng-container>
  <div nz-row>
    <div nz-col [nzSm]="{ offset: 0, span: 24 }" [nzXs]="{ offset: 0, span: 24 }">
      <h2>{{ 'code.title' | translate }}</h2>
      <h3 style="margin-bottom: 1rem">{{ 'code.subtitle' | translate }}</h3>
    </div>
  </div>

  <form nz-form [formGroup]="group" (ngSubmit)="onSubmit()">
    <nz-form-item>
      <nz-form-control
        [nzSm]="{ offset: 0, span: 24 }"
        [nzXs]="{ offset: 0, span: 14 }"
        nzErrorTip="{{ 'sign-up.error-tips.code-required' | translate }}"
      >
        <input
          type="text"
          nz-input
          placeholder="{{ 'common.code' | translate }}"
          formControlName="code"
          id="code"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control [nzSm]="{ offset: 0, span: 24 }" [nzXs]="{ offset: 0, span: 14 }">
        <button nz-button nzType="primary" nzSearch [nzLoading]="loading">
          {{ 'code.join' | translate }}
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-container>
