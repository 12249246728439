import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, OnDestroy, Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { LocalStorageService } from '../services/local-storage.service';

@Pipe({
  name: 'date',
  pure: false
})
export class CustomDatePipe extends DatePipe implements OnDestroy, PipeTransform {
  private onLangChange!: Subscription;
  private value: string | null = '';

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private localStorageService: LocalStorageService,
    private translate: TranslateService
  ) {
    super(locale);
  }

  ngOnDestroy(): void {
    this.dispose();
  }

  override transform(
    value: string | number | Date,
    format?: string | undefined,
    timezone?: string | undefined,
    locale?: string | undefined
  ): string | null;
  override transform(
    value: null | undefined,
    format?: string | undefined,
    timezone?: string | undefined,
    locale?: string | undefined
  ): null;
  override transform(
    value: string | number | Date | null | undefined,
    format?: string | undefined,
    timezone?: string | undefined,
    locale?: string | undefined
  ): string | null {
    const lang = this.localStorageService.getItem('lang');

    if (lang) {
      this.value = super.transform(value, format, timezone, lang);
    } else {
      this.value = super.transform(value, format, timezone, locale);
    }

    this.onLangChange = this.translate.onLangChange.subscribe(event => {
      this.value = super.transform(value, format, timezone, event.lang);
    });

    return this.value;
  }

  private dispose() {
    if (this.onLangChange) this.onLangChange.unsubscribe();
  }
}
