import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { forkJoin } from 'rxjs';

import { AuthService } from '@core/services/auth.service';
import { Organization } from '@features/models/organization.model';
import { _Organization } from '@features/models/profile.model';
import { Role } from '@features/models/role.model';
import { OrganizationService } from '@features/services/organization.service';
import { RoleService } from '@features/services/role.service';
import { FirestoreDocument } from '@shared/models/firestore-document';
import { ModalService } from '@shared/services/modal.service';
import { validateForm } from '@shared/utility/global.functions';

@Component({
  selector: 'app-select-organization',
  templateUrl: './select-organization.component.html',
  styleUrls: ['./select-organization.component.scss']
})
export class SelectOrganizationComponent implements OnInit {
  group!: FormGroup;
  loading = true;
  organizations: (Organization & FirestoreDocument)[] = [];
  roles: (Role & FirestoreDocument)[] = [];

  @Input() private id!: string;

  constructor(
    private authService: AuthService,
    private builder: FormBuilder,
    private modalRef: NzModalRef,
    private modalService: ModalService,
    private organizationService: OrganizationService,
    private roleService: RoleService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initData();
    this.initForm();
  }

  async onSubmit() {
    if (this.group.valid) {
      const organization: Organization & FirestoreDocument =
        this.group.value.organization || this.group.controls['organization'].value;

      const role: Role & FirestoreDocument = this.group.value.role;
      this.authService.setPermissions(role.permissions);

      await this.organizationService.select(
        {
          _id: organization._id,
          name: organization.name,
          roleId: role._id,
          roleName: role.name
        },
        {
          navigate: true,
          signOut: false
        }
      );

      this.modalRef.close();
    } else {
      validateForm(this.group);
    }
  }

  private initData() {
    forkJoin([this.organizationService.list(), this.roleService.list()]).subscribe({
      next: ([organizations, roles]) => {
        this.organizations = organizations;
        this.roles = roles.data;
        const organization = this.organizations.find(x => x._id === this.id);

        if (organization) {
          const control = this.group.controls['organization'];
          control.setValue(organization);
          control.disable();
        }

        this.loading = false;
      },
      error: error => {
        this.translate.get('assign-role.modal.init-error').subscribe((translation: string) => {
          this.modalService.onError(translation);
          this.modalRef.close();
        });
      }
    });
  }

  private initForm() {
    this.group = this.builder.group({
      organization: [null, [Validators.required]],
      role: [null, [Validators.required]]
    });
  }
}
