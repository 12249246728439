import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { lastValueFrom, take, timer } from 'rxjs';

import { AuthService } from '@core/services/auth.service';
import { AddInviteDto, InviteViewModel } from '@features/models/invite.model';
import { LicenseViewModel } from '@features/models/license.model';
import { Organization } from '@features/models/organization.model';
import { _Organization } from '@features/models/profile.model';
import { RoleViewModel } from '@features/models/role.model';
import { OrganizationService } from '@features/services/organization.service';
import { ProfileService } from '@features/services/profile.service';
import { RoleService } from '@features/services/role.service';
import { FirestoreDocument } from '@shared/models/firestore-document';
import { ModalService } from '@shared/services/modal.service';

@Component({
  selector: 'app-invites',
  templateUrl: './invites.component.html',
  styleUrls: ['./invites.component.scss']
})
export class InvitesComponent implements OnInit {
  group!: FormGroup;
  invites: InviteViewModel[] = [];
  isEmailFocused = false;
  licenses: LicenseViewModel[] = [];
  loading = false;
  organizations: (Organization & FirestoreDocument)[] = [];
  roles: RoleViewModel[] = [];
  tableLoading = false;

  // private allInvites: InviteViewModel[] = [];
  private organization!: (_Organization | Organization) & FirestoreDocument;

  constructor(
    public organizationService: OrganizationService,
    private authService: AuthService,
    private builder: FormBuilder,
    private clipboard: Clipboard,
    private modalRef: NzModalRef,
    private modalService: ModalService,
    private roleService: RoleService,
    private translate: TranslateService,
    private profileService: ProfileService
  ) {}

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.initData();
  }

  // onAdd(event: MouseEvent) {
  //   event.preventDefault();

  //   if (this.group.valid) {
  //     const email: string = this.group.value.email;

  //     if (this.emails.map(x => x.email).includes(email)) {
  //       this.translate.get('invites.modal.cannot-repeat').subscribe((translation: string) => {
  //         this.modalService.onError(translation);
  //       });
  //     } else {
  //       const invite: AddInviteDto = {
  //         email,
  //         role: this.group.value.role
  //       };

  //       this.emails.push(invite);
  //       this.group.controls['email'].reset();
  //     }
  //   } else {
  //     validateForm(this.group);
  //   }
  // }

  // onClose(index: number) {
  //   this.emails.splice(index, 1);
  // }

  onCopy(text: string) {
    this.clipboard.copy(text);
  }

  onDelete(id: string) {
    this.translate.get('invites.modal.confirm-delete').subscribe((translation: string) => {
      this.modalService.onConfirm(translation, () => this.deactivateInvite(id));
    });
  }

  onFormatRole(id: string) {
    const role = this.roles.find(x => x._id === id);
    return role?.name || id;
  }

  async onSubmit() {
    // try {
    //   this.loading = true;
    //   const allEmails = this.allInvites.map(x => x.email);
    //   const newEmails = this.emails.filter(x => !allEmails.includes(x.email));

    //   await Promise.all(
    //     newEmails.map(x => lastValueFrom(this.organizationService.addInvite(this.organization._id, x)))
    //   );

    //   this.loading = false;
    //   this.emails = [];
    //   this.initData();
    // } catch (error) {
    //   this.translate.get('invites.modal.submit-error').subscribe((translation: string) => {
    //     this.modalService.onError(translation);
    //     this.loading = false;
    //   });
    // }

    try {
      this.loading = true;
      const email = this.group.value.email;
      const license = this.group.value.license;
      const role = this.group.value.role;

      const { data } = await lastValueFrom(this.profileService.getId(email));

      const isInOrganization = await lastValueFrom(
        this.profileService.isInOrganization(data, this.organization._id)
      );

      if (!isInOrganization.data) {
        const body: AddInviteDto = { email, role };
        if (license) body.license = license;
        await lastValueFrom(this.organizationService.addInvite(this.organization._id, body));
      } else {
        this.translate
          .get('invites.errors.account-in-organization')
          .subscribe((translation: string) => {
            this.modalService.onError(translation);
            this.loading = false;
          });
      }

      this.loading = false;
      this.group.reset();
      await this.initData();
    } catch (error) {
      this.translate.get('invites.modal.submit-error').subscribe((translation: string) => {
        this.modalService.onError(translation);
        this.loading = false;
      });
    }
  }

  private async deactivateInvite(id: string) {
    try {
      this.tableLoading = true;
      await lastValueFrom(this.organizationService.deactivateInvite(this.organization._id, id));
      await this.initData();
    } catch (error) {
      this.translate.get('invites.modal.delete-error').subscribe((translation: string) => {
        this.modalService.onError(translation);
        this.tableLoading = false;
      });
    }
  }

  async resendInvite(invite: InviteViewModel) {
    try {
      this.tableLoading = true;

      await lastValueFrom(
        this.organizationService.resendInvite(this.organization._id, invite._id, {
          organizationName: this.organization.name,
          email: invite.email
        })
      );

      this.tableLoading = false;
      invite.sent = true;

      timer(10000)
        .pipe(take(1))
        .subscribe(() => {
          invite.sent = false;
        });
    } catch (error) {
      console.log(error);
    }
  }

  private async initData() {
    try {
      this.tableLoading = true;
      const organization = this.organizationService.organization;

      if (organization) {
        const [invites, roles] = await Promise.all([
          lastValueFrom(this.organizationService.listInvites(organization._id)),
          lastValueFrom(this.roleService.list())
        ]);

        // this.allInvites = invites.data;
        this.invites = invites.data.filter(x => x.email !== this.authService.user?.email);

        this.invites.sort((a, b) => {
          const dateA = new Date(a.createdOn);
          const dateB = new Date(b.createdOn);
          return dateB.getTime() - dateA.getTime();
        });

        this.roles = roles.data;
        this.organization = organization;
        this.tableLoading = false;
      } else {
        const [organizations, roles] = await Promise.all([
          lastValueFrom(this.organizationService.list()),
          lastValueFrom(this.roleService.list())
        ]);

        this.invites = [];
        this.licenses = [];
        this.organizations = organizations;
        this.roles = roles.data;
        this.tableLoading = false;

        this.group.get('organization')?.valueChanges.subscribe(async value => {
          this.tableLoading = true;

          const [invites, licenses] = await Promise.all([
            lastValueFrom(this.organizationService.listInvites(value)),
            lastValueFrom(this.organizationService.listLicenses(value))
          ]);

          // this.allInvites = invites.data;
          this.invites = invites.data.filter(x => x.email !== this.authService.user?.email);

          this.invites.sort((a, b) => {
            const dateA = new Date(a.createdOn);
            const dateB = new Date(b.createdOn);
            return dateB.getTime() - dateA.getTime();
          });

          this.licenses = licenses.data;
          this.organization = organizations.find(x => x._id === value) || organizations[0];
          this.tableLoading = false;
        });
      }
    } catch (error) {
      this.translate.get('invites.modal.init-error').subscribe((translation: string) => {
        this.modalService.onError(translation);
        this.modalRef.close();
      });
    }
  }

  private initForm() {
    this.group = this.builder.group({
      email: [null, [Validators.required, Validators.email]],
      license: [null],
      organization: [null],
      role: [null, [Validators.required]]
    });
  }
}
