import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, Subject, take, takeUntil } from 'rxjs';

import { MenuItem } from '@core/models/menu-item';
import { ResellerViewModel } from '@core/models/reseller.model';
import { AuthService } from '@core/services/auth.service';
import { MenuService } from '@core/services/menu.service';
import { ResellerService } from '@core/services/reseller.service';
import { ThemeService } from '@core/services/theme.service';
import { SelectOrganizationComponent } from '@features/components/select-organization/select-organization.component';
import { ViewInvitesComponent } from '@features/components/view-invites/view-invites.component';
import { _Organization } from '@features/models/profile.model';
import { OrganizationService } from '@features/services/organization.service';
import { ProfileService } from '@features/services/profile.service';
import { RoleService } from '@features/services/role.service';
import { FirestoreDocument } from '@shared/models/firestore-document';
import { LanguageItem } from '@shared/models/language-item.model';
import { I18nService } from '@shared/services/i18n.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { ModalService } from '@shared/services/modal.service';
import {
  AUTH,
  MASTER_DOMAIN,
  MISC_ROUTES,
  PROFILE,
  RESELLER
} from '@shared/utility/global.constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnDestroy, OnInit {
  languageList: LanguageItem[] = [];
  languageSelected!: string;
  menuChildren: (MenuItem & FirestoreDocument)[] = [];
  menuParents: (MenuItem & FirestoreDocument)[] = [];
  organizationsList: (_Organization & FirestoreDocument)[] = [];
  reseller?: ResellerViewModel;

  // Constants
  homeRoute = MISC_ROUTES.home;
  resellerRoute = RESELLER.route;
  userRoute = PROFILE.route;

  private dispose$ = new Subject<void>();

  constructor(
    public authService: AuthService,
    public organizationService: OrganizationService,
    private i18nService: I18nService,
    private auth: AngularFireAuth,
    private localStorageService: LocalStorageService,
    private menuService: MenuService,
    private modalService: ModalService,
    private profileService: ProfileService,
    private resellerService: ResellerService,
    private roleService: RoleService,
    private router: Router,
    private themeService: ThemeService,
    private titleService: Title,
    private translate: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.dispose$.next();
    this.dispose$.complete();
  }

  async ngOnInit(): Promise<void> {
    this.initLangs();
    this.initMenu();
    await this.initReseller();
    this.initOrganizations();
  }

  onChange(item: LanguageItem) {
    this.i18nService.setLocale(item.value);
    this.localStorageService.setItem('lang', item.value.lang);
    this.languageSelected = item.label;
  }

  async onGalleryRedirect() {
    try {
      const user = await lastValueFrom(this.auth.user.pipe(take(1)));
      if (!user) return;

      const token = await user.getIdToken();
      const redirectUrl = window.location.origin;
      let url = `${environment.gallery}/auth?token=${token}&redirectUrl=${redirectUrl}`;
      const organization = this.organizationService.organization?._id;
      if (organization) url += `&organization=${organization}`;
      window.open(url, '_blank');
    } catch (error) {
      console.log(error);
    }
  }

  async onVipAdminRedirect() {
    try {
      const user = await lastValueFrom(this.auth.user.pipe(take(1)));
      if (!user) return;

      const token = await user.getIdToken();
      const redirectUrl = window.location.origin;
      let url = `${environment.vipAdmin}/auth?token=${token}&redirectUrl=${redirectUrl}`;
      const organization = this.organizationService.organization?._id;
      if (organization) url += `&organization=${organization}`;
      window.open(url, '_blank');
    } catch (error) {
      console.log(error);
    }
  }

  async onSelect(organization: _Organization & FirestoreDocument, navigate: boolean) {
    try {
      const { data } = await lastValueFrom(this.roleService.get(organization.roleId));
      if (!data) return;

      const permissions = data.permissions || [];

      if (this.authService.user?.email === this.reseller?.admin) {
        permissions.push(RESELLER.permissions.get);
        permissions.push(RESELLER.permissions.update);
      }

      this.authService.setPermissions(permissions);
      await this.organizationService.select(organization, { navigate, signOut: false });
      this.localStorageService.setItem('organization', organization._id);

      const org = await lastValueFrom(this.organizationService.get(organization._id));

      if (org.data) {
        const perms = org.data.permissions || [];
        this.organizationService.setPermissions(perms);
      }
    } catch (error) {
      await this.organizationService.select(organization, { navigate, signOut: false });
    }
  }

  async onSignOut() {
    try {
      await this.authService.signOut();
      this.goToSignIn();
    } catch (error) {
      this.translate
        .get('sign-out.modal')
        .pipe(takeUntil(this.dispose$))
        .subscribe(translation => {
          this.modalService.onError(translation);
        });
    }
  }

  async onStopView() {
    this.authService.setPermissions([]);
    await this.organizationService.select(null, { navigate: true, signOut: false });
  }

  async onToggle() {
    await this.themeService.toggleTheme();
  }

  onView() {
    this.modalService.onCreate(SelectOrganizationComponent);
  }

  onInvite() {
    this.modalService.onCreate(ViewInvitesComponent);
  }

  private initLangs() {
    this.languageList = this.i18nService.languages;
    const lang = this.localStorageService.getItem('lang');

    if (lang) {
      const languageItem = this.i18nService.languages.find(x => x.value.lang === lang);

      if (languageItem) {
        this.onChange(languageItem);
      } else {
        this.languageSelected = this.i18nService.languages[0].label;
      }
    } else {
      this.languageSelected = this.i18nService.languages[0].label;
    }
  }

  private initMenu() {
    this.authService.user$.pipe(takeUntil(this.dispose$)).subscribe(async user => {
      this.menuParents = [];
      this.menuChildren = [];
      if (!user) return;

      if (!user.active) return await this.onSignOut();

      let parents = await lastValueFrom(this.menuService.listParents());
      let children = await lastValueFrom(this.menuService.listChildren());

      parents = parents.filter(x => !x.disabled).sort((a, b) => a.order - b.order);
      children = children.filter(x => !x.disabled).sort((a, b) => a.order - b.order);

      if (user.sa) {
        this.menuParents = parents.filter(
          x =>
            x.route !== RESELLER.route ||
            (x.route === RESELLER.route && this.reseller?.hostnames.includes(MASTER_DOMAIN))
        );

        this.menuChildren = children.sort((a, b) => a.order - b.order);
      } else {
        this.organizationService.organization$.pipe(takeUntil(this.dispose$)).subscribe(_ => {
          this.menuParents = parents.filter(item =>
            this.authService.permissions.includes(item.permission)
          );
          this.menuChildren = children.filter(item =>
            this.authService.permissions.includes(item.permission)
          );
        });
      }
    });
  }

  private async initReseller() {
    const reseller = this.localStorageService.getItem(RESELLER.key);

    if (reseller) {
      this.reseller = reseller;
      this.titleService.setTitle(reseller.name);
      return;
    }

    const { data } = await lastValueFrom(this.resellerService.getByHostname());

    if (data) {
      this.reseller = data;
      this.titleService.setTitle(data.name);
    }
  }

  private initOrganizations() {
    this.authService.user$.pipe(takeUntil(this.dispose$)).subscribe(user => {
      if (user) {
        this.profileService
          .listOrganizations(user._id)
          .pipe(takeUntil(this.dispose$))
          .subscribe({
            next: async organizations => {
              this.organizationsList = organizations;
              const organizationId = this.localStorageService.getItem('organization');

              if (organizations.length > 0) {
                const organization = organizations.find(x => x._id === organizationId);
                await this.onSelect(organization || organizations[0], false);
              }
            },
            error: error => {
              this.organizationsList = [];
            }
          });
      }
    });
  }

  private goToSignIn() {
    this.router.navigate(['/', AUTH.routes.signIn]).then(() => {
      location.reload();
    });
  }
}
