import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { map, Observable } from 'rxjs';

import { Skybox, SkyboxViewModel } from '../models/skybox.model';
import { ResellerViewModel } from '@core/models/reseller.model';
import { FirestoreDocument } from '@shared/models/firestore-document';
import { Result } from '@shared/models/result.model';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { RESELLER, SKYBOX } from '@shared/utility/global.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SkyboxService {
  constructor(
    private db: AngularFirestore,
    private localStorageService: LocalStorageService,
    private http: HttpClient
  ) {}

  delete(id: string) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${SKYBOX.collection}`;
    return this.db.collection<Skybox>(path).doc(id).delete();
  }

  get(id: string): Observable<(Skybox & FirestoreDocument) | undefined> {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${SKYBOX.collection}`;

    return this.db
      .collection<Skybox>(path)
      .doc(id)
      .get()
      .pipe(
        map(snapshot => {
          const data = snapshot.data();
          return data ? { _id: snapshot.id, ...data } : undefined;
        })
      );
  }

  list(): Observable<(Skybox & FirestoreDocument)[]> {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${SKYBOX.collection}`;

    return this.db
      .collection<Skybox>(path)
      .get()
      .pipe(map(snapshot => snapshot.docs.map(x => ({ _id: x.id, ...x.data() }))));
  }

  listSkybox(organization?: string) {
    let params = new HttpParams();
    if (organization) params = params.set('organization', organization);
    return this.http.get<Result<SkyboxViewModel[]>>(`${environment.api}${SKYBOX.endpoint}`, {
      params
    });
  }

  deleteSkybox(id: string, owner?: string, ownerId?: string) {
    const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
    return this.http.delete<Result<void>>(`${environment.api}${SKYBOX.endpoint}/${id}`, { params });
  }

  getSkybox(skyboxId: string, owner?: string, ownerId?: string) {
    const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
    return this.http.get<Result<SkyboxViewModel | undefined>>(
      `${environment.api}${SKYBOX.endpoint}/${skyboxId}`,
      { params }
    );
  }

  addSkybox(data: SkyboxViewModel) {
    return this.http.post<Result<void>>(`${environment.api}${SKYBOX.endpoint}/add`, data);
  }

  updateSkybox(id: string, data: Skybox) {
    return this.http.put<Result<void>>(`${environment.api}${SKYBOX.endpoint}/update/${id}`, data);
  }

  update(id: string, data: Skybox) {
    const reseller: ResellerViewModel = this.localStorageService.getItem(RESELLER.key);
    const path = `${RESELLER.collection}/${reseller._id}/${SKYBOX.collection}`;
    return this.db.collection<Skybox>(path).doc(id).update(data);
  }

  // ------------------------ NEW ------------------- //

  deleteSkyboxNew(id: string, owner?: string, ownerId?: string) {
    const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
    return this.http.delete<Result<void>>(`${environment.api}${SKYBOX.endpoint}/new/${id}`, {
      params
    });
  }

  getSkyboxNew(skyboxId: string, owner?: string, ownerId?: string) {
    const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
    return this.http.get<Result<SkyboxViewModel | undefined>>(
      `${environment.api}${SKYBOX.endpoint}/new/${skyboxId}`,
      { params }
    );
  }

  createSkybox(data: Skybox & FirestoreDocument, owner?: string, ownerId?: string) {
    const form = new FormData();
    form.append('_id', data._id);
    form.append('name', data.name);
    form.append('image', data.image);
    form.append('imageType', data.imageType);
    if (data.file) form.append('file', data.file);

    const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
    return this.http.post<Result<void>>(`${environment.api}${SKYBOX.endpoint}/new/add`, form, {
      params
    });
  }

  updateSkyboxNew(id: string, data: Skybox, owner?: string, ownerId?: string) {
    const form = new FormData();
    form.append('_id', id);
    form.append('name', data.name);
    form.append('image', data.image);
    form.append('imageType', data.imageType);
    if (data.file) form.append('file', data.file);

    const params = new HttpParams().set('owner', owner || '').set('ownerId', ownerId || '');
    return this.http.put<Result<void>>(
      `${environment.api}${SKYBOX.endpoint}/new/update/${id}`,
      form,
      {
        params
      }
    );
  }
}
