import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { OrganizationService } from '@features/services/organization.service';
import { ModalService } from '@shared/services/modal.service';

@Component({
  selector: 'app-assign-user',
  templateUrl: './assign-user.component.html',
  styleUrls: ['./assign-user.component.scss']
})
export class AssignUserComponent implements OnInit {
  group!: FormGroup;
  loading = true;

  listProfile: any;
  @Input() private id!: string;
  private idOrganization!: any;

  licenseName: any;

  constructor(
    private builder: FormBuilder,
    private modalService: ModalService,
    private organizationService: OrganizationService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private modalRef: NzModalRef
  ) {}

  ngOnInit(): void {
    this.idOrganization = this.organizationService.organization?._id;
  }
}
